import { apim, getAnonymousToken } from "@/constants/api"
import CONSTANTS, { oktaTokenEndpoint, signupEndPoint } from "@/constants/index"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { HYDRATE } from "next-redux-wrapper"
import { authClient } from "@/services/auth.service"
import { removeCustomerCartId } from "@/utils/cart"
import {
  caesarDecrypt,
  clearAnonymousToken,
  clearAuthToken,
  getAccessToken,
  getAnonymousId,
  getUserPersona,
  revalidateTime,
  shaEncrypt,
  createPersonaCookie,
  hourDifference,
  getShortenedUrl,
} from "@/utils/helper"
import { mergeCart } from "@/store/features/cartSlice"
import { getConfig } from "@/constants/config"
import CONS from "@/constants"
import _isEmpty from "lodash/isEmpty"
import { showCartExpandedView, showToast } from "./genericSlice"

const initialState = {
  isAuth: false,
  anonymousId: "",
  access_token: "",
  refresh_token: "",
  expires_in: "",
  revalidate_in: "",
  scopes: [],
  token_type: "",
  status: "",
  error: "",
  authModal: {
    show: false,
    staticText: {},
    active: 0,
  },
  hashedEmail: "",
  customerProspect: "",
  showForgotPassword: false,
  showCustomerSignup: false,
  showProSignup: false,
  hideCustomerSignupCTA: false,
  user: {},
  name: {},
  loggedInUser: {},
  closeAuth: false,
}

export const getOktaToken = createAsyncThunk(
  "auth/getOktaToken",
  async type => {
    const { gId } = getAccessToken()
    const response = await apim.post(
      `${oktaTokenEndpoint}?scope=${type}&channel=india`
    )
    return response.data
  }
)

export const getAnonymousGuestToken = createAsyncThunk(
  "auth/getOktaToken",
  async () => {
    const response = await apim.post(
      `${oktaTokenEndpoint}?scope=guest&channel=india`
    )
    console.log(response, "response")
    const { access_token: accessToken = "" } =
      response?.data || response?.payload?.data
    console.log(accessToken, "accessToken")
    localStorage.setItem("okta-anonymous-access-token", accessToken)
    return response
  }
)

export const getOTP = createAsyncThunk(
  "get/otp/",
  async (payload, thunkAPI) => {
    const { mobileNumber, getOTPWithEmail } = payload
    try {
      const resp = await authClient.idx.authenticate({
        username: mobileNumber,
        authenticators: getOTPWithEmail ? ["okta_email"] : ["phone_number"],
      })
      const { status } = await authClient.idx.proceed({
        methodType: getOTPWithEmail ? "email" : "sms",
      })
      if (status === "PENDING") {
        return thunkAPI.fulfillWithValue({
          status: "success",
        })
      } else {
        return thunkAPI.fulfillWithValue({
          invalidNumber: true,
        })
      }
    } catch (err) {
      return thunkAPI.fulfillWithValue({ error: true })
    }
  }
)

export const getEmailOTPForLogin = createAsyncThunk(
  "get/otp/",
  async (payload, thunkAPI) => {
    const { mobileNumber } = payload
    try {
      const resp = await authClient.idx.authenticate({
        username: mobileNumber,
        authenticators: ["okta_email"],
      })
      const { status } = await authClient.idx.proceed({
        methodType: "email",
      })
      if (status === "PENDING") {
        return thunkAPI.fulfillWithValue({
          status: "success",
        })
      } else {
        return thunkAPI.fulfillWithValue({
          invalidNumber: true,
        })
      }
    } catch (err) {
      return thunkAPI.fulfillWithValue({ error: true })
    }
  }
)

export const clearPreviousToken = createAsyncThunk("get/otp/", async () => {
  await authClient.session.close()
  await authClient.tokenManager.clear()
})

export const validateOTP = createAsyncThunk(
  "auth/login",
  async (payload, thunkAPI) => {
    const {
      otpEnteredByUser,
      isFirstLogin = false,
      isReplace = false,
      isMobile = false,
    } = payload
    const state = thunkAPI.getState()
    const { cart } = state?.cart
    try {
      const res = await authClient.idx.proceed({
        verificationCode: otpEnteredByUser,
      })
      if (res.status === "SUCCESS") {
        authClient.tokenManager.setTokens(res.tokens)
        localStorage.setItem("userLoggedIn", true)
        return authClient.token
          .getWithoutPrompt({
            responseType: ["id_token", "access_token", "refresh_token"],
            sessionToken: res.sessionToken,
          })
          .then(async function ({ tokens }) {
            await authClient.tokenManager.setTokens(tokens)
            const payload = {
              accessToken: tokens?.accessToken?.accessToken,
              refreshToken: tokens.refreshToken.refreshToken,
              user: tokens?.accessToken?.claims || {},
              isAuth: true,
            }
            await thunkAPI.dispatch(postLogin(payload))
            await thunkAPI.dispatch(setAuthData(payload))
            const state = thunkAPI.getState()
            const mergeCartPayload = {
              anonymousId: getAnonymousId(),
            }
            if (isReplace) {
              mergeCartPayload.action = "replace"
            }
            if (!isFirstLogin && mergeCartPayload.anonymousId) {
              await thunkAPI.dispatch(mergeCart(mergeCartPayload))
              if (cart?.lineItems?.length) {
                await thunkAPI.dispatch(
                  showToast({
                    message:
                      "Your cart may have been updated. Please review the cart.",
                    isVisible: true,
                  })
                )
                const onClick = async () => {
                  await thunkAPI.dispatch(showCartExpandedView(true))
                  window.scrollTo(0, 1000, { behavior: "smooth" })
                }
                if (isMobile) {
                  await thunkAPI.dispatch(
                    showToast({
                      message: `Your cart is updated. <a href='#' class="underline" onclick="${onClick()}">Click here</a> to view the items.`,
                      isVisible: true,
                    })
                  )
                }
              }
            } else {
              thunkAPI.dispatch(setAnonymousId(""))
            }
            // ------------ functionality review start ----------------
            const loginCallback = window.loginCallback
            const { hideCustomerSignupCTA } = state?.auth
            if (loginCallback) {
              if (hideCustomerSignupCTA) {
                const persona = getUserPersona()
                const { general: { homepageUrl = "" } = {} } = await getConfig()
                window.KPNSignIn ? window.location.replace(loginCallback) : null
              } else {
                if (typeof loginCallback === "function") {
                  loginCallback()
                } else {
                  window.location.replace(loginCallback)
                  // await authClient.session.setCookieAndRedirect(res.sessionToken)
                }
                window.loginCallback = null
              }
            }
            // ------------ functionality review end ----------------
            // await authClient.session.setCookieAndRedirect(res.sessionToken)

            return thunkAPI.fulfillWithValue("success")
          })
          .catch(function (err) {
            return thunkAPI.fulfillWithValue(err?.errorSummary || "error")
          })
      } else if (
        res?.messages?.[0]?.message === CONSTANTS.INVALID_OTP ||
        res?.messages?.[0]?.message === CONSTANTS.SESSION_EXPIRED
      ) {
        return thunkAPI.fulfillWithValue({
          invalidOTP: true,
          data: res.data,
        })
      } else if (res?.messages?.[0]?.message === CONSTANTS.TOO_MANY_ATTEMPTS) {
        {
          return thunkAPI.fulfillWithValue({
            tooManyAttempts: true,
            data: res.data,
          })
        }
      }
    } catch (err) {
      return thunkAPI.fulfillWithValue(err?.errorSummary || "error")
    }
  }
)

export const userLogin = createAsyncThunk(
  "auth/login",
  async (payload, thunkAPI) => {
    const {
      username,
      password,
      isFirstLogin = false,
      isReplace = false,
    } = payload
    try {
      const res = await authClient.signInWithCredentials({
        username,
        password,
      })
      if (res.status === "SUCCESS") {
        return authClient.token
          .getWithoutPrompt({
            responseType: ["id_token", "access_token"],
            sessionToken: res.sessionToken,
          })
          .then(async function ({ tokens }) {
            clearAnonymousToken()
            authClient.tokenManager.setTokens(tokens)
            const payload = {
              accessToken: tokens.accessToken.accessToken,
              refreshToken: tokens.refreshToken.refreshToken,
              user: tokens.accessToken?.claims || {},
              isAuth: true,
            }

            await thunkAPI.dispatch(postLogin(payload))
            const state = thunkAPI.getState()
            const mergeCartPayload = {
              anonymousId: getAnonymousId(),
            }
            if (isReplace) {
              mergeCartPayload.action = "replace"
            }
            if (!isFirstLogin && mergeCartPayload.anonymousId) {
              await thunkAPI.dispatch(mergeCart(mergeCartPayload))
              dispatch(
                showToast({
                  message:
                    "Your cart may have been updated. Please review the cart.",
                  isVisible: true,
                })
              )
            } else {
              thunkAPI.dispatch(setAnonymousId(""))
            }
            // ------------ functionality review start ----------------
            const loginCallback = window.loginCallback
            const { hideCustomerSignupCTA } = state?.auth
            if (loginCallback) {
              if (hideCustomerSignupCTA) {
                const persona = getUserPersona()
                const { general: { homepageUrl = "" } = {} } = await getConfig()
                if (persona !== "TVS") {
                  window.location.replace(
                    await getShortenedUrl(homepageUrl ?? "/")
                  )
                } else
                  window.KPNSignIn
                    ? window.location.replace(loginCallback)
                    : null
              } else {
                if (typeof loginCallback === "function") {
                  loginCallback()
                } else {
                  window.location.replace(loginCallback)
                  // await authClient.session.setCookieAndRedirect(res.sessionToken)
                }
                window.loginCallback = null
              }
            }
            // ------------ functionality review end ----------------
            // await authClient.session.setCookieAndRedirect(res.sessionToken)

            return thunkAPI.fulfillWithValue("success")
          })
          .catch(function (err) {
            return thunkAPI.fulfillWithValue(err?.errorSummary || "error")
          })
      } else if (res.status === "LOCKED_OUT") {
        return thunkAPI.fulfillWithValue({ lockedOut: true })
      } else if (res.status === "PASSWORD_EXPIRED") {
        return thunkAPI.fulfillWithValue({
          passwordExpired: true,
          data: res.data,
        })
      }
    } catch (err) {
      return thunkAPI.fulfillWithValue(err?.errorSummary || "error")
    }
  }
)

export const login = createAsyncThunk(
  "auth/login",
  async (payload, thunkAPI) => {
    const {
      username,
      password,
      isFirstLogin = false,
      isReplace = false,
    } = payload
    try {
      const res = await authClient.signInWithCredentials({
        username,
        password,
      })
      if (res.status === "SUCCESS") {
        return authClient.token
          .getWithoutPrompt({
            responseType: ["id_token", "access_token", "refresh_token"],
            sessionToken: res.sessionToken,
          })
          .then(async function ({ tokens }) {
            await authClient.tokenManager.setTokens(tokens)
            const payload = {
              accessToken: tokens.accessToken.accessToken,
              refreshToken: tokens.refreshToken.refreshToken,
              user: tokens.accessToken?.claims || {},
              isAuth: true,
            }

            await thunkAPI.dispatch(postLogin(payload))
            const state = thunkAPI.getState()
            const mergeCartPayload = {
              anonymousId: getAnonymousId(),
            }
            if (isReplace) {
              mergeCartPayload.action = "replace"
            }
            if (!isFirstLogin && mergeCartPayload.anonymousId) {
              await thunkAPI.dispatch(mergeCart(mergeCartPayload))
              dispatch(
                showToast({
                  message:
                    "Your cart may have been updated. Please review the cart.",
                  isVisible: true,
                })
              )
            } else {
              thunkAPI.dispatch(setAnonymousId(""))
            }
            // ------------ functionality review start ----------------
            const loginCallback = window.loginCallback
            const { hideCustomerSignupCTA } = state?.auth
            if (loginCallback) {
              if (hideCustomerSignupCTA) {
                const persona = getUserPersona()
                const { general: { homepageUrl = "" } = {} } = await getConfig()
                if (persona !== "TVS") {
                  window.location.replace(
                    await getShortenedUrl(homepageUrl ?? "/")
                  )
                } else
                  window.KPNSignIn
                    ? window.location.replace(loginCallback)
                    : null
              } else {
                if (typeof loginCallback === "function") {
                  loginCallback()
                } else {
                  window.location.replace(loginCallback)
                  // await authClient.session.setCookieAndRedirect(res.sessionToken)
                }
                window.loginCallback = null
              }
            }
            // ------------ functionality review end ----------------
            // await authClient.session.setCookieAndRedirect(res.sessionToken)

            return thunkAPI.fulfillWithValue("success")
          })
          .catch(function (err) {
            return thunkAPI.fulfillWithValue(err?.errorSummary || "error")
          })
      } else if (res.status === "LOCKED_OUT") {
        return thunkAPI.fulfillWithValue({ lockedOut: true })
      } else if (res.status === "PASSWORD_EXPIRED") {
        return thunkAPI.fulfillWithValue({
          passwordExpired: true,
          data: res.data,
        })
      }
    } catch (err) {
      return thunkAPI.fulfillWithValue(err?.errorSummary || "error")
    }
  }
)

export const signup = createAsyncThunk(
  "auth/signup",
  async (payload, thunkAPI) => {
    try {
      const response = await apim.post(`${signupEndPoint}`, payload)
      return response.data
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data)
    }
  }
)

export const forgotPassword = createAsyncThunk(
  "auth/forgotPassword",
  async (payload, thunkAPI) => {
    const { username } = payload
    try {
      const CONFIG = await getConfig()
      const { oktaChangePasswordUri } = CONFIG?.apiEndpoints
      const response = await authClient.forgotPassword({
        username,
        factorType: "EMAIL",
        relayState: oktaChangePasswordUri,
      })
      return response.data
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  }
)

const deleteAllCookies = () => {
  const cookies = document.cookie.split(";")

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i]
    const eqPos = cookie.indexOf("=")
    const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT"
  }
}

export const logout = createAsyncThunk(
  "auth/logout",
  async (payload, thunkAPI) => {
    try {
      let apimHost = process.env.NEXT_PUBLIC_APIMBASEURL
      const oktaTokenStorage =
        JSON.parse(localStorage.getItem("okta-token-storage")) || {}
      const caConfig = JSON.parse(localStorage.getItem("caConfig"))
      const idToken = oktaTokenStorage?.["idToken"]
      const accessToken = oktaTokenStorage?.["accessToken"]
      await authClient.signOut({
        revokeAccessToken: true,
        idToken,
        accessToken,
        clearTokensBeforeRedirect: true,
      })
      localStorage.clear()
      localStorage.setItem("caConfig", JSON.stringify(caConfig))
      localStorage.setItem("userLoggedIn", false)
      deleteAllCookies()
      createPersonaCookie()
      removeCustomerCartId()
      await thunkAPI.dispatch(clearPreviousToken())
      await thunkAPI.dispatch(clearAuthState())
      await authClient.session.close()
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data)
    }
  }
)

export const postLogin = createAsyncThunk("auth/postLogin", async payload => {
  clearAnonymousToken()
  const { user = {} } = payload
  if (user?.sub) {
    // this is email id hashing
    const res = await shaEncrypt(user?.sub)
    createPersonaCookie()
    return {
      ...payload,
      encryptedEmail: res,
    }
  }
})

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearAuthState(state, action) {
      state.isAuth = false
      state.anonymousId = ""
      state.access_token = ""
      state.refresh_token = ""
      state.revalidate_in = ""
      state.user = {}
      state.hashedEmail = ""
      state.customerProspect = ""
      clearAuthToken()
      if (window) {
        window.authAccessToken = ""
      }
    },
    closeAuthShow(state, action) {
      state.closeAuth = action.payload
    },
    setAuthData(state, action) {
      const {
        accessToken = "",
        refreshToken = "",
        isAuth = false,
        user = {},
        name = {},
      } = action.payload
      if (accessToken) {
        state.access_token = accessToken
        if (window) {
          window.authAccessToken = accessToken
        }
        if (!isAuth) {
          localStorage.setItem("okta-anonymous-access-token", accessToken)
        }
      }
      if (refreshToken) {
        state.refresh_token = refreshToken
        localStorage.setItem("okta-anonymous-refresh-token", refreshToken)
      }
      if (isAuth) state.isAuth = isAuth
      if (user) state.user = user
      if (name) state.name = name
      state.revalidate_in = revalidateTime()
    },
    setUserData(state, action) {
      state.loggedInUser = action.payload
    },
    setRevalidateTime(state, action) {
      state.revalidate_in = revalidateTime()
    },
    setAuthModalVisibility(state, action) {
      const { show, active = 0 } = action.payload
      state.authModal = {
        ...state.authModal,
        show: show,
        active: active,
      }
      state.showCustomerSignup = false
      state.showProSignup = false
      state.showForgotPassword = false
    },
    setAuthModalActiveTab(state, action) {
      state.authModal = {
        ...state.authModal,
        active: action.payload,
      }
    },
    setAuthModalStaticText(state, action) {
      state.authModal = {
        ...state.authModal,
        staticText: action.payload,
      }
    },
    setAnonymousId(state, action) {
      const anonymousId = action.payload
      state.anonymousId = anonymousId
      localStorage.setItem("anonymousId", anonymousId)
    },
    setShowCustomerSignup(state, action) {
      state.showCustomerSignup = action.payload
      state.showProSignup = false
      state.showForgotPassword = false
      state.authModal = {
        ...state.authModal,
        show: action.payload,
      }
    },
    setShowProSignup(state, action) {
      state.showProSignup = action.payload
      state.showCustomerSignup = false
      state.showForgotPassword = false
      state.authModal = {
        ...state.authModal,
        show: action.payload,
      }
    },
    setShowForgotPassword(state, action) {
      state.showForgotPassword = action.payload
      state.showCustomerSignup = false
      state.showProSignup = false
      state.authModal = {
        ...state.authModal,
        show: action.payload,
      }
    },
    setHideCustomerSignupCTA(state, action) {
      state.hideCustomerSignupCTA = action.payload
    },
    setPersona(state, action) {
      const {
        isAuth,
        user: { uid },
      } = state
      const { user } = state

      if (isAuth) {
        sessionStorage.setItem(
          CONS.KF_UPDATE_MEMBERSHIP,
          JSON.stringify({ uid })
        )
        localStorage.setItem(CONS.KF_FORCE_RENEW_TOKEN, true)

        state.user = {
          ...user,
          persona: [action.payload],
        }
      }
    },
    setUserName(state, action) {
      const { isAuth, user } = state
      const [firstName = "", lastName = ""] = action?.payload ?? []
      if (isAuth) {
        state.user = {
          ...user,
          firstName,
          lastName,
        }
      }
    },
    setAuthModalFormData(state, action) {
      state.authModal.formData = action.payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase([HYDRATE], (state, action) => {
        return {
          ...state,
          ...action.payload.auth,
        }
      })
      .addCase(getOktaToken.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(getOktaToken.fulfilled, (state, action) => {
        const {
          access_token: accessToken = "",
          refresh_token: refreshToken = "",
          expires_in: expiresIn = "",
          scopes = [],
          token_type: tokenType = "",
        } = action.payload
        if (accessToken) {
          state.access_token = accessToken
          if (window) {
            window.authAccessToken = accessToken
          }
          localStorage.setItem("okta-anonymous-access-token", accessToken)
        }
        if (refreshToken) {
          state.refresh_token = refreshToken
          localStorage.setItem("okta-anonymous-refresh-token", refreshToken)
        }

        if (expiresIn) state.expires_in = expiresIn
        if (scopes) state.scopes = scopes
        if (tokenType) state.token_type = tokenType
        state.revalidate_in = revalidateTime()
        state.status = "succeeded"
        state.error = ""
      })
      .addCase(getOktaToken.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
      .addCase(login.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(login.rejected, (state, action) => {
        console.log("LOGIN", action.payload)
      })
      .addCase(login.fulfilled, (state, action) => {
        console.log("LOGIN", action.payload)
      })
      .addCase(signup.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(signup.rejected, (state, action) => {
        console.log("SIGNUP rejected", action.error)
      })
      .addCase(signup.fulfilled, (state, action) => {
        console.log("SIGNUP fulfilled", action.payload)
      })
      .addCase(postLogin.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(postLogin.fulfilled, (state, action) => {
        const {
          encryptedEmail = "",
          accessToken = "",
          refreshToken = "",
          user = {},
          isAuth = "",
        } = action.payload
        const oldHashedEmail = state.hashedEmail
        const oldCustomerProspect = state.customerProspect
        if (accessToken) {
          state.access_token = accessToken
          if (window) {
            window.authAccessToken = accessToken
          }
        }

        if (refreshToken) state.refresh_token = refreshToken
        if (!_isEmpty(user)) {
          state.user = user
        }
        if (isAuth !== "") state.isAuth = isAuth
        if (encryptedEmail) state.hashedEmail = encryptedEmail
        state.revalidate_in = revalidateTime()

        let needsUpdate = false
        if (oldCustomerProspect && oldHashedEmail === encryptedEmail) {
          // here check if existing customerProspects are outdated
          try {
            const decryptedCaesar = caesarDecrypt(
              encryptedEmail,
              oldCustomerProspect
            )
            const decryptedJSON = JSON.parse(decryptedCaesar)
            const currDate = new Date().toString()
            if (
              decryptedJSON.updatedAt &&
              hourDifference(decryptedJSON.updatedAt, currDate) >= 1
            ) {
              needsUpdate = true
            }
          } catch (e) {
            needsUpdate = true
          }
        } else {
          needsUpdate = true
        }
        if (needsUpdate) {
          /**
           * checking 3 conditions here
           *  1. if hashed emails are different
           *  2. if hashed emails are same then check if sync is done 1 hour ago or before that
           *  3. if old customer prospect object is missing from localstorage
           */
          // await getPastOrders(true)
        }
      })
      .addCase(postLogin.rejected, (state, action) => {
        console.log(action.error)
      })
  },
})

export const {
  clearAuthState,
  setAuthData,
  setUserData,
  setRevalidateTime,
  setAuthModalVisibility,
  setAuthModalActiveTab,
  setAuthModalStaticText,
  setAnonymousId,
  setShowCustomerSignup,
  setShowProSignup,
  setShowForgotPassword,
  setHideCustomerSignupCTA,
  setPersona,
  setUserName,
  setAuthModalFormData,
  closeAuthShow,
} = authSlice.actions
export const selectAuthState = state => state.auth
export default authSlice.reducer
