import * as globalUtils from "../src/utils/cart"

import _get from "lodash/get"
import { apim } from "@/constants/api"
import { getStockEndPoint } from "@/constants/index"
import { store } from "@/store"
import { getConfig } from "@/constants/config"
import { getShortenedUrl } from "@/utils/location"

import { setCartUrl } from "@/store/features/cartSlice"

const getStock = async sku => {
  let stock = 0
  try {
    const { data: { results = [] } = {} } = await apim.get(
      `${getStockEndPoint}/?sku=${sku}`
    )
    stock = results.reduce((acc, cur) => acc + cur.availableQuantity, 0)
  } catch (err) {
    console.error(err)
  }
  return stock
}

export const getStockCached = async sku => {
  const cacheDuration = 2 * 60 * 1000
  const now = Date.now()

  if (!window.stockCache) {
    window.stockCache = {}
  }

  if (
    window.stockCache[sku] &&
    now - window.stockCache[sku].timestamp < cacheDuration
  ) {
    return window.stockCache[sku].stock
  }

  let stock = 0
  try {
    const { data: { results = [] } = {} } = await apim.get(
      `${getStockEndPoint}/?sku=${sku}`
    )
    stock = results.reduce((acc, cur) => acc + cur.availableQuantity, 0)

    window.stockCache[sku] = { stock, timestamp: now }
  } catch (err) {
    console.error(err)
  }

  return stock
}

const getQuantityCount = data => {
  if (!(data && data.length)) {
    return 0
  }
  const totalCount =
    data &&
    data
      .filter(item => {
        const category = item.variant?.attributes?.find(
          attr => attr.name === "ProductLocalCategory"
        )
        return (
          !item.custom?.fields?.isBundleChildItem ||
          category?.value === "Install Services"
        )
      })
      .reduce((accumulator, item) => {
        return accumulator + item.quantity
      }, 0)

  return totalCount
}

const getCartCount = () => {
  const { cartCount } = store?.getState()?.cart ?? 0
  return cartCount
}

const getAriaLabel = (t, cartCount) => {
  const ariaLabel =
    cartCount > 1
      ? t("kf.minicart.aria.label.cartItems", { count: cartCount })
      : t("kf.minicart.aria.label.cartItem", { count: cartCount })
  return ariaLabel
}

const setCartLandingPagePath = async () => {
  const config = await getConfig()
  const cartLandingUrl = await getShortenedUrl(
    _get(config, "general.cartLandingPagePath", "/")
  )
  store.dispatch(setCartUrl(cartLandingUrl))
}

const getCartLandingPagePath = async () => {
  const config = await getConfig()
  const cartLandingUrl = await getShortenedUrl(
    _get(config, "general.cartLandingPagePath", "/")
  )
  return cartLandingUrl
}
const getCartPagePath = () => {
  let path = "/"
  path = getCartLandingPagePath().then(res => {
    return res
  })
  return path
}

export const getCustomerCartId = () => {
  return localStorage.getItem("customerCartId")
}

export const getCartIdParams = isSubmit => {
  const {
    auth: { user: { persona = [] } = {} } = {},
    cart: { cart: { id = "" } = {} } = {},
  } = store.getState()
  const isIscUser = persona?.includes("IN_Kohler_ISC")
  const customerCartId = getCustomerCartId()
  const cartId = isIscUser && customerCartId ? `&cartId=${customerCartId}` : ""
  const cartIdQuery =
    isIscUser && customerCartId
      ? `?cartId=${customerCartId}`
      : isSubmit
      ? `?cartId=${id}`
      : ""

  return { cartId, cartIdQuery }
}

export const deleteFromCart = async cb => {
  await store
    .dispatch(deleteCart())
    .unwrap()
    .then(res => {
      if (res) {
        cb && cb("success", null, res ? res : {})
        store.dispatch(setCart({ lineItems: [] }))
        store.dispatch(setNoshipping([]))
        store.dispatch(setCartCount(0))
        store.dispatch(setShipping([]))
        const cartPayload = {
          currency: "INR",
        }
        store.dispatch(createCart(cartPayload))
      }
    })
    .catch(() => {
      cb && cb("failure", "failed to empty your cart.")
    })
}

export const getETACached = async (
  isBundle = false,
  payload = [],
  isMustOrder = false
) => {
  try {
    const now = new Date().getTime()
    const cacheDuration = 2 * 60 * 1000
    const SKU = isBundle ? payload.parentSKU : payload.sku

    if (!window.etaCache) {
      window.etaCache = {}
    }

    const isCacheValid =
      !isMustOrder &&
      window.etaCache[SKU] &&
      window.etaCache[SKU].pincode === payload.pincode &&
      payload.qty <= window.etaCache[SKU].availableQty &&
      now - window.etaCache[SKU].lastCacheTime <= cacheDuration &&
      window.etaCache[SKU].lastQty < payload.qty

    if (!isCacheValid) {
      window.etaCache = {}

      const { data: res = {} } = await apim.post(
        "/catalog/stock-availability/eta" +
          (isBundle ? "?type=bundle" : isMustOrder ? "?type=must-order" : ""),
        payload
      )

      const cacheData = etaResponse => {
        const {
          result: { data: { warehouse = [], items = [], sku = "" } = {} } = {},
        } = etaResponse

        let availableQty = 0

        if (isBundle) {
          availableQty = items.reduce((minQty, item, index) => {
            const itemQty =
              item.warehouse?.reduce(
                (total, warehouse) => total + warehouse.availableQty,
                0
              ) || 0

            return index === 0 ? itemQty : Math.min(minQty, itemQty)
          }, 0)
        } else {
          availableQty =
            warehouse?.reduce((total, wh) => total + wh.availableQty, 0) || 0
        }

        if (etaResponse.status !== "fail") {
          window.etaCache[isBundle ? SKU : sku] = {
            data: JSON.parse(JSON.stringify(etaResponse)),
            pincode: payload.pincode,
            availableQty,
            lastCacheTime: now,
            lastQty: payload.qty,
            warehouse: isMustOrder
              ? JSON.parse(JSON.stringify(etaResponse.result?.data?.warehouse))
              : [],
          }
        }
      }

      isMustOrder
        ? res.result?.data.forEach(item => cacheData(item))
        : cacheData(res)
      return res
    }

    if (window.etaCache[SKU]) {
      window.etaCache[SKU].lastQty = payload.qty
    }

    return JSON.parse(JSON.stringify(window.etaCache[SKU].data))
  } catch (ex) {
    console.log("Fetching ETA failed", ex)
    return {}
  }
}

export {
  getStock,
  getQuantityCount,
  getCartCount,
  getAriaLabel,
  setCartLandingPagePath,
  getCartPagePath,
  getCartLandingPagePath,
}

export * from "../src/utils/cart"
